import { BrowserRouter, Route, Routes } from 'react-router-dom';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Admin from '../Admin/Admin';
import Home from '../Home/Home';
import Login from '../Login/Login';
import RobotList from '../Admin/RobotList/RobotList';
import AdminSettings from '../Admin/AdminSettings/AdminSettings';
import RobotCreate from '../Admin/RobotCreate/RobotCreate';
import RobotUpdate from '../Admin/RobotUpdate/RobotUpdate';

function App() {

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/admin" element={<Admin />}>
            <Route path="" element={<RobotList />} />
            <Route path="create" element={<RobotCreate />} />
            <Route path="settings" element={<AdminSettings />} />
            <Route path="bot/:botId" element={<RobotUpdate />} />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
