import { Box, Button, TextField } from '@mui/material';
import React, { useEffect, useReducer } from 'react';
import base64 from 'react-native-base64';
import { useNavigate } from 'react-router-dom';
import useToken from '../../hooks/useToken';
import './Login.scss';

export default function Login() {
    const { token, setToken } = useToken();
    let navigate = useNavigate();

    if (token) {
        navigate('/');
    }

    const reducer = (state, action) => {
        // eslint-disable-next-line default-case
        switch (action.type) {
            case 'setUsername':
                return {
                    ...state,
                    username: action.payload
                };
            case 'setPassword':
                return {
                    ...state,
                    password: action.payload
                };
            case 'setIsButtonDisabled':
                return {
                    ...state,
                    isButtonDisabled: action.payload
                };
            case 'loginSuccess':
                return {
                    ...state,
                    helperText: action.payload,
                    isError: false
                };
            case 'loginFailed':
                return {
                    ...state,
                    helperText: action.payload,
                    isError: true
                };
            case 'setIsError':
                return {
                    ...state,
                    isError: action.payload
                };
        }
    }

    const initialState = {
        username: '',
        password: '',
        isButtonDisabled: true,
        isError: false,
        helperText: ''
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        if (state.username.trim() && state.password.trim()) {
            dispatch({
                type: 'setIsButtonDisabled',
                payload: false
            });
        } else {
            dispatch({
                type: 'setIsButtonDisabled',
                payload: true
            });
        }
    }, [state.username, state.password]);

    async function loginUser() {
        return fetch(`${process.env.REACT_APP_APIURL}/auth/login`, {
            method: 'POST',
            // credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Basic ${base64.encode(`${state.username}:${state.password}`)}`
            },
            mode: 'cors'
        })
            .then(data => {
                if (data.ok)
                    return data.json();
                dispatch({ type: 'loginFailed', payload: 'Неверная пара логин/пароль' });
                return { token: null };
            })
            .catch(() => { reducer(state, 'loginFailed'); return { token: null }; })
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const { token } = await loginUser()
        if (token) {
            setToken(token);
            navigate('/', { replace: true });
        }
    };

    const handleKeyPress = (event) => {
        if (event.keyCode === 13 || event.which === 13) {
            state.isButtonDisabled || handleSubmit(event);
        }
    };

    const handleUsernameChange =
        (event) => {
            dispatch({
                type: 'setUsername',
                payload: event.target.value
            });
        };

    const handlePasswordChange =
        (event) => {
            dispatch({
                type: 'setPassword',
                payload: event.target.value
            });
        }

    return (
        <section className="section-logo">
            <div className="form-container">
                <h1>Авторизация</h1>
                <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                    onSubmit={handleSubmit}
                    helperText={state.helperText}
                >
                    <div>
                        <TextField
                            error={state.isError}
                            fullWidth
                            required
                            id="username"
                            name="username"
                            label="Логин"
                            variant="outlined"
                            sx={{ mt: 1, mb: 1 }}
                            autoFocus
                            autoComplete="username"
                            onChange={handleUsernameChange}
                            onKeyPress={handleKeyPress}
                            helperText={state.helperText}
                        />
                    </div>
                    <div>
                        <TextField
                            error={state.isError}
                            fullWidth
                            required
                            id="password"
                            name="password"
                            label="Пароль"
                            variant="outlined"
                            type="password"
                            autoComplete="current-password"
                            sx={{ mt: 1, mb: 1 }}
                            onChange={handlePasswordChange}
                            onKeyPress={handleKeyPress}
                            helperText={state.helperText}
                        />
                    </div>
                    <div>
                        <Button
                            variant="contained"
                            type="submit"
                            sx={{ mt: 1, mb: 1 }}
                            size="large"
                            disabled={state.isButtonDisabled}>
                            Войти
                        </Button>
                    </div>
                </Box>
            </div>
        </section>
    )
}