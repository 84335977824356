import React, { useRef } from "react";
import BotMessageChoise from "./BotMessageChoise";
import BotMessageSimple from "./BotMessageSimple";

export default function BotMessage({ type, children, messageId, editingMessage, setEditingMessage, messageElementRef, token, messageList, setMessageList, bot }) {

    const refEmojiList = useRef({});

    const handleBotMessageCancel = () => {
        setEditingMessage(0);
    }

    const handleShowEmojiList = () => {
        refEmojiList.current['emojiList' + messageId].style.display = 'flex';
    }

    const handleBotMessageRemove = (messageId) => {
        fetch(`${process.env.REACT_APP_APIURL}/message-block/${messageId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            mode: 'cors'
        }).then(data => {
            if (data.status === 204) {
                let ind = 0;
                messageList.every((v, i) => {
                    if (v.id === messageId) {
                        ind = i;
                        return false;
                    }
                    return true;
                });
                if (ind === 0) {
                    setMessageList([...messageList.slice(1)]);
                } else {
                    setMessageList([...messageList.slice(0, ind), ...messageList.slice(ind + 1)]);
                }
                messageElementRef.current[messageList[ind + 1]?.id]?.scrollIntoView({ block: 'center', inline: 'start', behavior: 'auto' });
            }
        });
    }

    switch (type) {
        case 'SIMPLE':
            return <BotMessageSimple messageId={messageId} editingMessage={editingMessage} setEditingMessage={setEditingMessage} messageElementRef={messageElementRef} content={children} refEmojiList={refEmojiList} token={token} messageList={messageList} setMessageList={setMessageList} handleBotMessageRemove={handleBotMessageRemove} handleShowEmojiList={handleShowEmojiList} handleBotMessageCancel={handleBotMessageCancel} />;

        case 'CHOISE':
            let content = JSON.parse(children);
            return <BotMessageChoise editingMessage={editingMessage} messageId={messageId} messageElementRef={messageElementRef} content={content} setEditingMessage={setEditingMessage} token={token} messageList={messageList} setMessageList={setMessageList} refEmojiList={refEmojiList} handleBotMessageRemove={handleBotMessageRemove} handleShowEmojiList={handleShowEmojiList} handleBotMessageCancel={handleBotMessageCancel} bot={bot} />;

        default:
            return '';
    }
}