import { Clear, Edit } from "@mui/icons-material";
import { ButtonBase, Grid, IconButton, styled } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PageHeader } from "../../../elements/PageHeader";
import useToken from "../../../hooks/useToken";

const ButtonAdd = styled(ButtonBase)(({ theme }) => ({
    position: 'relative',
    aspectRatio: '1 / 1',
    width: '100%',
    borderRadius: '6px',
    border: '1px solid #ccc',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.3s ease',
    '&:hover, &.Mui-focusVisible': {
        boxShadow: '0 5px 3px #eee',
        'span': {
            opacity: 1
        }
    }
}))

const ButtonPlus = styled('span')(({ theme }) => ({
    opacity: 0.7,
    color: '#bbb',
    fontSize: '3em',
    transition: 'all 0.3s ease',
}))

const BotName = styled(Grid)(({ theme }) => ({
    color: '#333',
    fontSize: '1.1em',
    transition: 'all 0.3s ease',
    textAlign: 'center',
    '& span': {
        opacity: 0.8
    }
}))

const BotItem = styled(Grid)(({ theme }) => ({
    width: '100%',
    aspectRatio: '1 / 1',
    borderRadius: '6px',
    border: '1px solid #ccc',
    transition: 'all 0.3s ease',
    '&:hover, &.Mui-focusVisible': {
        boxShadow: '0 5px 3px #eee',
        'span': {
            opacity: 1
        }
    }
}))

export default function RobotList() {

    const { token } = useToken();
    let navigate = useNavigate();

    if (!token) {
        navigate('/');
    }

    const [botList, setBotList] = useState([]);

    useEffect(() => {
        const getBotList = async () => {
            return fetch(`${process.env.REACT_APP_APIURL}/bot?fields=id,name,description`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                mode: 'cors'
            }).then((data) => {
                if (data.ok) {
                    return data.json();
                }
                return new Promise([]);
            }).then((data) => {
                setBotList(data);
            });
        }

        // return () => {
        getBotList()
        // }
    }, [token])

    const editButtonHandle = (id) => {
        navigate(`/admin/bot/${id}`, { replace: true });
    }

    const BotList = () => {
        const renderList = botList.map((v) => (
            <Grid key={v.id} item xs={2} container>
                <BotItem item xs container direction="column">
                    <BotName item xs container alignItems="center" justifyContent="center"><span>{v.name}</span></BotName>
                    <Grid item xs container alignContent="end" justifyContent="end">
                        <IconButton onClick={() => editButtonHandle(v.id)} color="primary" size="small"><Edit /></IconButton>
                        <IconButton color="error" size="small"><Clear /></IconButton>
                    </Grid>
                </BotItem>
            </Grid>
        ));

        return (
            <Grid item container spacing={2}>
                {renderList}
                <Grid item xs={2}>
                    <ButtonAdd key={'add'} onClick={() => navigate('/admin/create', { replace: true })}>
                        <ButtonPlus>+</ButtonPlus>
                    </ButtonAdd>
                </Grid>
            </Grid>
        );
    }

    return (
        <>
            <Grid item xs={1}>
                <PageHeader>Список ботов</PageHeader>
            </Grid>
            <div style={{ padding: '20px 36px' }}>
                <BotList />
            </div>
        </>
    );
}