import { AltRoute, TextFields } from "@mui/icons-material";
import { Box, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import React from "react";

export default function BotToolList({ messageList, selectedSection, token, setMessageList, setEditingMessage, messageListRef, messageElementRef, sectionList }) {

    const getSortMessage = () => {
        let sort = 0;
        messageList.every(v => {
            sort = Math.max(sort, v.sort);
            return true;
        });
        return sort + 100;
    }

    const handlePutToolSimpleMessage = (event) => {
        const message = {
            section_id: selectedSection,
            sort: getSortMessage(),
            type: 'SIMPLE',
            content: 'Текстовое сообщение'
        }
        fetch(`${process.env.REACT_APP_APIURL}/message-block`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            mode: 'cors',
            body: JSON.stringify(message)
        }).then(data => data.json()).then(data => {
            setMessageList([...messageList, data]);
            setEditingMessage(data.id);
            messageListRef.current?.scrollIntoView({ behavior: "smooth" });
            messageElementRef.current[data.id]?.focus();
        });
    }

    const handlePutToolChoise = (event) => {
        const message = {
            section_id: selectedSection,
            sort: getSortMessage(),
            type: 'CHOISE',
            content: JSON.stringify({
                text: 'Выберите вариант',
                variant: [{
                    code: '0',
                    description: 'В начало',
                    sectionId: sectionList[0].id,
                    sectionName: sectionList[0].name
                }]
            })
        }
        fetch(`${process.env.REACT_APP_APIURL}/message-block`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            mode: 'cors',
            body: JSON.stringify(message)
        }).then(data => data.json()).then(data => {
            setMessageList([...messageList, data]);
            setEditingMessage(data.id);
            messageListRef.current?.scrollIntoView({ behavior: "smooth" });
            messageElementRef.current[data.id]?.focus();
        });
    }

    return <Box sx={{
        width: '100%',
    }}>
        <h2>Инструменты</h2>
        <List sx={{ width: '100%' }}>
            <ListItemButton onClick={handlePutToolSimpleMessage}>
                <ListItemIcon>
                    <TextFields />
                </ListItemIcon>
                <ListItemText>
                    Сообщение
                </ListItemText>
            </ListItemButton>
            <ListItemButton onClick={handlePutToolChoise}>
                <ListItemIcon>
                    <AltRoute />
                </ListItemIcon>
                <ListItemText>
                    Навигация
                </ListItemText>
            </ListItemButton>
        </List>
    </Box>;
}