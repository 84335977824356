import { Add, Check, Clear, Close, Delete, Edit, EmojiEmotions } from "@mui/icons-material";
import { Box, Fab, Grid, IconButton, List, ListItem, ListItemButton, TextField, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import React, { useRef, useEffect, useState } from "react";
import emojiData from "../../../json/data.json";

export default function BotMessageChoise({ editingMessage, messageId, messageElementRef, content, setEditingMessage, token, messageList, setMessageList, refEmojiList, handleBotMessageRemove, handleShowEmojiList, handleBotMessageCancel, bot }) {
    const refVariantCode = useRef([]);
    const refVariantName = useRef([]);
    const refVariantLink = useRef([]);
    const [sectionList, setSectionList] = useState();
    const [variantContent, setVariantContent] = useState({});
    const [cursorPosition, setCursorPosition] = useState(null);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_APIURL}/section?per-page=50&filter[bot_id]=${bot.id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            mode: 'cors'
        }).then(data => data.json()).then(data => {
            setSectionList(data);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editingMessage]);

    useEffect(() => {
        setVariantContent(content.variant);
    }, [content.variant]);

    const handleBotChoiseMessageEdit = (messageId) => {
        setEditingMessage(messageId);

        setTimeout(() => {
            messageElementRef.current[messageId].focus();
        }, 500);
    }

    const handleBotChoiseMessageSave = (messageId) => {
        const vars = [];
        refVariantCode.current.every((v, i) => {
            if (v.value !== '') {
                let el = {
                    code: v.value,
                    description: refVariantName.current[i].value,
                    sectionId: refVariantLink.current[i].getElementsByTagName('input')[0].value,
                    sectionName: refVariantLink.current[i].getElementsByTagName('div')[0].innerHTML,
                }
                vars.push(el);
            }
            return true;
        });
        fetch(`${process.env.REACT_APP_APIURL}/message-block/${messageId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            mode: 'cors',
            body: JSON.stringify({
                content: JSON.stringify({
                    text: messageElementRef.current[messageId].innerHTML,
                    variant: vars
                })
            })
        }).then((data) => {
            if (data.ok) {
                return data.json();
            }
            return null;
        }).then(data => {
            if (data) {
                let ind = 0;
                messageList.every((v, i) => {
                    if (v.id === data.id) {
                        ind = i;
                        return false;
                    }
                    return true;
                });
                setEditingMessage(0);
                setMessageList([...messageList.slice(0, ind), data, ...messageList.slice(ind + 1)]);
                messageElementRef.current[messageId].scrollIntoView({ block: 'center', inline: 'start', behavior: 'auto' });
            }
        });
    }

    const handleAddVariant = () => {
        setVariantContent([...variantContent, {
            code: '',
            description: '',
            sectionId: ''
        }]);
    }

    const handleInsertEmoji = (symbol) => {
        refEmojiList.current['emojiList' + messageId].style.display = 'none';
        messageElementRef.current[messageId].focus();
        let sel = window.getSelection();
        sel.removeAllRanges();
        let node = document.createElement('span');
        node.innerHTML = symbol;
        cursorPosition.insertNode(node);
        sel.addRange(cursorPosition);
        sel.collapseToEnd();
    }

    const handleRemoveVariant = (ind) => {
        console.log(ind);
        console.log(variantContent[ind]);
        console.log(variantContent.slice(0, ind));
        refVariantCode.current.splice(ind);
        if (ind === 0) {
            console.log([...variantContent.slice(1)]);
            setVariantContent([...variantContent.slice(1)]);
        } else {
            setVariantContent([...variantContent.slice(0, ind), ...variantContent.slice(ind + 1)]);
        }
    }

    return <Box sx={{
        width: '75%',
        borderRadius: '4px',
        background: '#fff',
        padding: '12px',
        paddingBottom: editingMessage === messageId ? '40px' : '12px',
        margin: '6px 0',
        position: 'relative',
        '& div:focus-visible': {
            outline: 'none'
        },
        '& .edit-button, & .trash-button': {
            display: 'none'
        },
        '&:hover .edit-button, &:hover .trash-button': {
            display: editingMessage === messageId ? 'none' : 'inline-flex'
        }
    }}>
        <div
            contentEditable={editingMessage === messageId}
            suppressContentEditableWarning={true}
            ref={(element) => messageElementRef.current[messageId] = element}
            dangerouslySetInnerHTML={{ __html: content.text }}
            onKeyUp={(event) => { setCursorPosition(window.getSelection().getRangeAt(0)); }}
            onClick={(event) => { setCursorPosition(window.getSelection().getRangeAt(0)); }}>
        </div>
        <List disablePadding>
            {Object.keys(variantContent)?.map((objectKey, index) => {
                if (editingMessage === messageId) {
                    return <ListItem key={index} sx={{ paddingTop: '20px' }}>
                        <Grid container alignItems="center">
                            <Grid item xs={3}>
                                <TextField
                                    label="Код"
                                    name={`variant-code-${index}`}
                                    ref={element => refVariantCode.current[index] = element?.getElementsByTagName('input')[0]}
                                    defaultValue={variantContent[index].code}
                                    xs={2}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    label="Описание"
                                    name={`variant-name-${index}`}
                                    ref={element => refVariantName.current[index] = element?.getElementsByTagName('input')[0]}
                                    defaultValue={variantContent[index].description}
                                    sx={{ width: '100%' }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl sx={{ width: '100%' }}>
                                    <InputLabel>Переход</InputLabel>
                                    <Select
                                        id={`variant-section-${index}`}
                                        defaultValue={variantContent[index].sectionId}
                                        label="Переход"
                                        ref={element => refVariantLink.current[index] = element}
                                        sx={{ width: '100%' }}
                                        name={`variant-link-${index}`}
                                    >
                                        <MenuItem key={-1} value="">(нет)</MenuItem>
                                        {sectionList.map((v, i) => {
                                            return <MenuItem key={i} value={v.id}>{v.name}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton color="error" onClick={(event) => { handleRemoveVariant(index) }}>
                                    <Clear />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </ListItem>
                }
                return <ListItem key={index} onClick={(event) => { }} disablePadding>
                    <ListItemButton sx={{ padding: 0 }}>
                        {variantContent[index].code}. {variantContent[index].description}
                    </ListItemButton>
                </ListItem>
            })}
        </List>
        <Box
            sx={{
                display: 'none',
                position: 'absolute',
                width: '50%',
                background: '#fff',
                right: 0,
                zIndex: 500,
                maxHeight: '300px',
                overflowY: 'auto',
                padding: '20px',
                boxSizing: 'border-box',
                flexFlow: 'row wrap',
                justifyContent: 'space-between',
                boxShadow: '0 0 3px #000',
                '& .item-emoji': {
                    cursor: 'pointer',
                    margin: '6px',
                    fontSize: '120%'
                }
            }}
            ref={element => refEmojiList.current['emojiList' + messageId] = element}>
            {emojiData.map((v, i) => <span className="item-emoji" key={'emoji-' + messageId + '-' + i} onClick={(event) => { handleInsertEmoji(event.target.innerHTML) }}>{v.symbol}</span>)}
        </Box>
        <Fab
            size="small"
            color="info"
            className="edit-button"
            onClick={(event) => { handleBotChoiseMessageEdit(messageId) }}
            sx={{
                position: 'absolute',
                right: '50px',
                bottom: '-13px',
                zIndex: 50
            }}><Edit /></Fab>
        <Fab
            size="small"
            color="error"
            className="trash-button"
            onClick={(event) => { handleBotMessageRemove(messageId) }}
            sx={{
                position: 'absolute',
                right: '5px',
                bottom: '-13px',
                zIndex: 50
            }}><Delete /></Fab>
        <Fab
            size="small"
            color="primary"
            className="add-variant-button"
            onClick={(event) => { handleAddVariant() }}
            sx={{
                display: (editingMessage === messageId ? 'inline-flex' : 'none'),
                position: 'absolute',
                right: '140px',
                bottom: '-13px',
                zIndex: 100
            }}><Add /></Fab>
        <Fab
            size="small"
            color="secondary"
            className="emoji-button"
            onClick={(event) => { handleShowEmojiList() }}
            sx={{
                display: (editingMessage === messageId ? 'inline-flex' : 'none'),
                position: 'absolute',
                right: '95px',
                bottom: '-13px',
                zIndex: 100
            }}><EmojiEmotions /></Fab>
        <Fab
            size="small"
            color="success"
            onClick={(event) => { handleBotChoiseMessageSave(messageId) }}
            sx={{
                display: (editingMessage === messageId ? 'inline-flex' : 'none'),
                position: 'absolute',
                right: '50px',
                bottom: '-13px',
                zIndex: 100
            }}><Check /></Fab>
        <Fab
            size="small"
            color="error"
            onClick={(event) => { handleBotMessageCancel() }}
            sx={{
                display: (editingMessage === messageId ? 'inline-flex' : 'none'),
                position: 'absolute',
                right: '5px',
                bottom: '-13px',
                zIndex: 100
            }}><Close /></Fab>
    </Box>;
}