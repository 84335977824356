import { Add, ArrowForwardIos, Delete, Edit } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, List, ListItem, ListItemText, TextField } from "@mui/material";
import React, { useState } from "react";

export default function BotSectionList({selectedSection,setSelectedSection,token,setMessageList,sectionList,setSectionList,bot,maxSort,setMaxSort}) {
    const [nameEditSection, setNameEditSection] = useState();
    const [idEditSection, setIdEditSection] = useState();
    const [editSectionOpenPopup, setEditSectionOpenPopup] = useState(false);
    const [addSectionOpenPopup, setAddSectionOpenPopup] = useState(false);

    const handleSelectSection = (id) => {
        setSelectedSection(id);
        fetch(`${process.env.REACT_APP_APIURL}/message-block?filter[section_id]=${id}&sort=sort`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            mode: 'cors'
        }).then((data) => data.json()).then((data) => setMessageList(data));
    }

    const handleBotSectionRemove = (id) => {
        fetch(`${process.env.REACT_APP_APIURL}/section/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            mode: 'cors'
        }).then((data) => {
            if (data.status === 204) {
                let ind = 0;
                sectionList.every((v, i) => {
                    if (v.id === id) {
                        ind = i;
                        return false;
                    }
                    return true;
                });
                setSectionList([...sectionList.slice(0, ind), ...sectionList.slice(ind + 1)]);
            }
        });
    }

    const BotSectionEditPopup = () => {
        const [isButtonDisabled, setIsButtonDisabled] = useState(true);
        const [tmpName, setTmpName] = useState(nameEditSection);

        const saveSection = () => {
            fetch(`${process.env.REACT_APP_APIURL}/section/${idEditSection}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                mode: 'cors',
                body: JSON.stringify({
                    bot_id: bot.id,
                    name: tmpName
                })
            }).then((data) => data.json()).then((data) => {
                let ind = 0;
                sectionList.every((v, i) => {
                    if (v.id === idEditSection) {
                        ind = i;
                        return false;
                    }
                    return true;
                });
                setSectionList([...sectionList.slice(0, ind), data, ...sectionList.slice(ind + 1)]);
            });
            setEditSectionOpenPopup(false);
        }

        const handleNameChange =
            (event) => {
                setIsButtonDisabled(true)
                setTmpName(event.target.value);
                if (event.target.value !== '') {
                    setIsButtonDisabled(false)
                }
            };

        const handleKeyPress = (event) => {
            if (event.keyCode === 13 || event.which === 13) {
                isButtonDisabled || handleButtonSave(event);
            }
        };

        const handleButtonSave = (event) => {
            saveSection();
            setEditSectionOpenPopup(false);
        }

        const cancelEditSectionHandle = () => {
            setEditSectionOpenPopup(false);
        }

        return (
            <Dialog onClose={cancelEditSectionHandle} open={editSectionOpenPopup} maxWidth="sm" fullWidth={true}>
                <DialogTitle>Название раздела</DialogTitle>
                <DialogContent>
                    <DialogContentText>

                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="editName"
                        label="Имя раздела"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={handleNameChange}
                        onKeyPress={handleKeyPress}
                        defaultValue={nameEditSection}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={cancelEditSectionHandle}>Отмена</Button>
                    <Button onClick={handleButtonSave} disabled={isButtonDisabled}>Сохранить</Button>
                </DialogActions>
            </Dialog>
        )
    }

    const BotSectionAddPopup = () => {
        const [isButtonDisabled, setIsButtonDisabled] = useState(true);
        const [nameSection, setNameSection] = useState("");

        const saveNewSection = () => {
            fetch(`${process.env.REACT_APP_APIURL}/section`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                mode: 'cors',
                body: JSON.stringify({
                    bot_id: bot.id,
                    name: nameSection,
                    sort: maxSort + 100
                })
            }).then((data) => data.json()).then((data) => {
                setMaxSort(data.sort);
                setSectionList([...sectionList, data]);
                setSelectedSection(data.id);
                setMessageList([]);
            });
            setAddSectionOpenPopup(false);
        }

        const handleNameChange =
            (event) => {
                setIsButtonDisabled(true)
                setNameSection(event.target.value);
                if (event.target.value !== '') {
                    setIsButtonDisabled(false)
                }
            };

        const handleKeyPress = (event) => {
            if (event.keyCode === 13 || event.which === 13) {
                isButtonDisabled || handleButtonSave(event);
            }
        };

        const handleButtonSave = (event) => {
            saveNewSection();
            setAddSectionOpenPopup(false);
        }

        const cancelAddSectionHandle = () => {
            setAddSectionOpenPopup(false);
        }

        return (
            <Dialog onClose={cancelAddSectionHandle} open={addSectionOpenPopup} maxWidth="sm" fullWidth={true}>
                <DialogTitle>Название раздела</DialogTitle>
                <DialogContent>
                    <DialogContentText>

                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Имя раздела"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={handleNameChange}
                        onKeyPress={handleKeyPress}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={cancelAddSectionHandle}>Отмена</Button>
                    <Button onClick={handleButtonSave} disabled={isButtonDisabled}>Дальше <ArrowForwardIos /></Button>
                </DialogActions>
            </Dialog>
        )
    }

    return (
        <>
            <BotSectionAddPopup />
            <BotSectionEditPopup />
            <List>
                <ListItem key={"addSection"}>
                    <Button onClick={() => { setAddSectionOpenPopup(true) }} variant="outlined"><Add /> Новый раздел</Button>
                </ListItem>
                {sectionList.map((v) => (
                    <ListItem key={'section' + v.id} selected={v.id === selectedSection} onClick={(event) => handleSelectSection(v.id)} button={true} sx={{
                        position: 'relative',
                        '& .edit-button, & .delete-button': {
                            display: 'none'
                        },
                        '&:hover .edit-button, &:hover .delete-button': {
                            display: 'inline-flex'
                        },
                    }}>
                        <ListItemText primary={v.name} />
                        <Fab
                            size="small"
                            color="info"
                            className="edit-button"
                            onClick={(event) => {
                                setIdEditSection(v.id);
                                setNameEditSection(v.name);
                                setEditSectionOpenPopup(true);
                                event.stopPropagation();
                            }}
                            sx={{
                                position: 'absolute',
                                right: '50px',
                                bottom: '3px',
                                zIndex: 50
                            }}><Edit /></Fab>
                        <Fab
                            size="small"
                            color="error"
                            className="delete-button"
                            onClick={(event) => { handleBotSectionRemove(v.id) }}
                            sx={{
                                position: 'absolute',
                                right: '5px',
                                bottom: '3px',
                                zIndex: 100
                            }}><Delete /></Fab>
                    </ListItem>
                ))}
            </List>
        </>
    )
}