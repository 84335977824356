import { ArrowForwardIos } from '@mui/icons-material';
import { Box, Button, Grid, TextField } from '@mui/material';
import React, { useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageHeader } from '../../../elements/PageHeader';
import useToken from '../../../hooks/useToken';


export default function RobotCreate() {
    const { token } = useToken();
    let navigate = useNavigate();

    if (!token) {
        navigate('/');
    }

    const reducer = (state, action) => {
        // eslint-disable-next-line default-case
        switch (action.type) {
            case 'setName':
                return {
                    ...state,
                    name: action.payload
                };
            case 'setIsButtonDisabled':
                return {
                    ...state,
                    isButtonDisabled: action.payload
                };
            case 'createSuccess':
                return {
                    ...state,
                    helperText: action.payload,
                    isError: false
                };
            case 'createFailed':
                return {
                    ...state,
                    helperText: action.payload,
                    isError: true
                };
            case 'setIsError':
                return {
                    ...state,
                    isError: action.payload
                };
        }
    }

    const initialState = {
        name: '',
        isButtonDisabled: true,
        isError: false,
        helperText: ''
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        if (state.name.trim()) {
            dispatch({
                type: 'setIsButtonDisabled',
                payload: false
            });
        } else {
            dispatch({
                type: 'setIsButtonDisabled',
                payload: true
            });
        }
    }, [state.name]);

    const botSave = async () => {
        return fetch(`${process.env.REACT_APP_APIURL}/bot`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            mode: 'cors',
            body: JSON.stringify({ name: state.name })
        })
            .then(data => {
                if (data.status === 422) {
                    dispatch({ type: 'createFailed', payload: 'Бот с таким именем уже существует' });
                    return null;
                }
                if (data.ok) {
                    dispatch({ type: 'createSuccess', payload: 'Бот создан успешно' });
                    return data.json();
                }
                return null;
            });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const bot = await botSave();
        if (bot) {
            navigate(`/admin/bot/${bot.id}`, { replace: true });
        }
    };

    const handleKeyPress = (event) => {
        if (event.keyCode === 13 || event.which === 13) {
            state.isButtonDisabled || handleSubmit(event);
        }
    };

    const handleNameChange =
        (event) => {
            dispatch({
                type: 'setName',
                payload: event.target.value
            });
        };

    return <>
        <Grid item xs={1}>
            <PageHeader>Новый бот</PageHeader>
        </Grid>
        <Grid item container xs spacing={2}>
            <Grid item xs md={4}>
                <div className="form-container" style={{
                    padding: '0 16px'
                }}>
                    <h1>Название</h1>
                    <Box
                        component="form"
                        noValidate
                        autoComplete="off"
                        onSubmit={handleSubmit}
                        helperText={state.helperText}
                    >
                        <div>
                            <TextField
                                error={state.isError}
                                fullWidth
                                required
                                id="name"
                                name="name"
                                label="Имя бота"
                                variant="outlined"
                                sx={{ mt: 1, mb: 1 }}
                                autoFocus
                                autoComplete="off"
                                onChange={handleNameChange}
                                onKeyPress={handleKeyPress}
                                helperText={state.helperText}
                            />
                        </div>
                        <div>
                            <Button
                                variant="contained"
                                type="submit"
                                sx={{ mt: 1, mb: 1 }}
                                size="large"
                                disabled={state.isButtonDisabled}>
                                Далее <ArrowForwardIos />
                            </Button>
                        </div>
                    </Box>
                </div>
            </Grid>
        </Grid>
    </>
}