import React, { useEffect, useReducer, useState } from "react";
import { Alert, Box, Button, Collapse, Grid, TextField } from "@mui/material";
import { PageHeader } from "../../../elements/PageHeader";
import { Save } from "@mui/icons-material";
import useToken from "../../../hooks/useToken";
import { useNavigate } from "react-router-dom";

export default function AdminSettings() {

    const { token } = useToken();
    let navigate = useNavigate();

    if (!token) {
        navigate('/');
    }

    const defaultSettings = {
        tgBotName: '',
        tgBotApiKey: ''
    }

    const [settingsData, setSettingsData] = useState(defaultSettings);

    const reducer = (state, action) => {
        // eslint-disable-next-line default-case
        switch (action.type) {
            case 'saveSuccess':
                return {
                    ...state,
                    helperText: action.payload,
                    isError: false
                };
            case 'saveFailed':
                return {
                    ...state,
                    helperText: action.payload,
                    isError: true
                };
            case 'setIsError':
                return {
                    ...state,
                    isError: action.payload
                };
        }
    }

    const initialState = {
        isError: false,
        helperText: ''
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        const getSettings = async () => {
            fetch(`${process.env.REACT_APP_APIURL}/settings/index`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                mode: 'cors'
            }).then((data) => {
                if (data.ok) {
                    return data.json();
                }
                return new Promise([]);
            }).then((data) => {
                setSettingsData(data);
            });
        }
        // if (settingsData===undefined) {
        getSettings();
        // }
    }, [token]);

    const settingsSave = async () => fetch(`${process.env.REACT_APP_APIURL}/settings/save`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        mode: 'cors',
        body: JSON.stringify(settingsData)
    }).then(data => {
        if (data.status !== 200) {
            dispatch({ type: 'saveFailed', payload: 'Ошибка сохранения данных' });
            return null;
        }
        if (data.ok) {
            dispatch({ type: 'saveSuccess', payload: 'Настройки сохранены успешно' });
            return data.json();
        }
        return null;
    })

    const handleSubmit = async (event) => {
        event.preventDefault();
        const settings = await settingsSave();
        setSettingsData(settings);
    };

    const handleSettingFieldChange = (event) => {
        const { name, value } = event.currentTarget;
        setSettingsData({ ...settingsData, [name]: value });
    }

    return (
        <>
            <Grid item xs={1}>
                <PageHeader>Настройки</PageHeader>
            </Grid>
            <Grid item xs>
                <div style={{ padding: '20px 36px' }}>
                    <Box
                        component="form"
                        noValidate
                        autoComplete="off"
                        onSubmit={handleSubmit}
                    >
                        <h2>Telegram</h2>
                        <div>
                            <TextField
                                fullWidth
                                id="tgBotName"
                                name="tgBotName"
                                label="Имя бота"
                                variant="outlined"
                                sx={{ mt: 1, mb: 1 }}
                                autoComplete="off"
                                onChange={handleSettingFieldChange}
                                value={settingsData.tgBotName}
                            />
                            <TextField
                                fullWidth
                                id="tgBotApiKey"
                                name="tgBotApiKey"
                                label="API KEY"
                                variant="outlined"
                                sx={{ mt: 1, mb: 1 }}
                                autoFocus
                                autoComplete="off"
                                onChange={handleSettingFieldChange}
                                value={settingsData.tgBotApiKey}
                            />
                        </div>
                        <Collapse in={state.helperText}>
                            <Alert sx={{ mb: 2 }}>
                                {state.helperText}
                            </Alert>
                        </Collapse>
                        <Collapse in={state.isError}>
                            <Alert sx={{ mb: 2 }} severity="error">
                                {state.isError}
                            </Alert>
                        </Collapse>
                        <div>
                            <Button
                                variant="contained"
                                type="submit"
                                sx={{ mt: 1, mb: 1 }}
                                size="large">
                                Сохранить <Save />
                            </Button>
                        </div>
                    </Box>
                </div>
            </Grid>
        </>
    );
}