import React from 'react';
import { Navigate } from 'react-router-dom';
import useToken from '../../hooks/useToken';

export default function Home() {
    const { token } = useToken();

    if (!token) {
        return <Navigate to="/login"></Navigate>;
    }

    return <Navigate to="/admin"></Navigate>;
}