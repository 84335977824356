import { styled } from "@mui/material";

export const PageHeader = styled('header')(({ theme }) => ({
    display: 'flex',
    flex: 'auto',
    alignItems: 'center',
    fontSize: '1.4em',
    height: '100%',
    padding: '0 1em',
    background: '#efefef'
}))