import React from 'react'
import { Logout, Settings, ViewComfy } from "@mui/icons-material";
import { ListItemIcon, ListItemText, MenuItem, MenuList } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import useToken from '../../hooks/useToken';

export default function MainMenu() {
    let navigate = useNavigate();
    const { logout } = useToken();
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const selectItem = (callback, index) => {
        setSelectedIndex(index);
        callback();
    }

    return (
        <nav aria-label="main-menu" className="main-menu">
            <MenuList>
                <MenuItem onClick={() => selectItem(() => navigate("/admin", { replace: true }), 1)} selected={1 === selectedIndex}>
                    <ListItemIcon>
                        <ViewComfy />
                    </ListItemIcon>
                    <ListItemText>
                        Список роботов
                    </ListItemText>
                </MenuItem>
                <MenuItem onClick={() => selectItem(() => navigate("/admin/settings", { replace: true }), 2)} selected={2 === selectedIndex}>
                    <ListItemIcon>
                        <Settings />
                    </ListItemIcon>
                    <ListItemText primary="Настройки" />
                </MenuItem>
                <MenuItem onClick={() => logout(() => navigate('/', { replace: true }))}>
                    <ListItemIcon>
                        <Logout />
                    </ListItemIcon>
                    <ListItemText primary="Выход" />
                </MenuItem>
            </MenuList>
        </nav>
    );
}