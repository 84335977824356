import { Box } from "@mui/material";
import React from "react";
import BotMessage from "./BotMessage";

export default function BotMessageList({ editingMessage, setEditingMessage, messageElementRef, token, messageList, setMessageList, messageListRef,bot }) {

    return <Box sx={{
        border: '1px solid #000',
        width: '100%',
        maxWidth: '800px',
        maxHeight: '100%',
        overflowY: 'auto',
        display: 'flex',
        background: '#4c91c7',
        padding: '15px',
        flexDirection: 'column',
        boxSizing: 'border-box'
    }}>
        {messageList.map((v) => <BotMessage key={'mess' + v.id} type={v.type} messageId={v.id} editingMessage={editingMessage} setEditingMessage={setEditingMessage} messageElementRef={messageElementRef} token={token} messageList={messageList} setMessageList={setMessageList} bot={bot}>{v.content}</BotMessage>)}
        <div ref={messageListRef} />
    </Box>
}