import { Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PageHeader } from "../../../elements/PageHeader";
import useToken from "../../../hooks/useToken";
import BotMessageList from "./BotMessageList";
import BotSectionList from "./BotSectionList";
import BotToolList from "./BotToolList";

export default function RobotUpdate() {
    const { token } = useToken();
    let navigate = useNavigate();

    if (!token) {
        navigate('/');
    }

    let params = useParams();

    const [bot, setBot] = useState({});
    const [sectionList, setSectionList] = useState([]);
    const [messageList, setMessageList] = useState([]);
    const [selectedSection, setSelectedSection] = useState(0);
    const [maxSort, setMaxSort] = useState(0);
    const [editingMessage, setEditingMessage] = useState(0);

    const messageListRef = useRef(null);
    const messageElementRef = useRef({});

    useEffect(() => {
        const createBeginSection = async (id) => {
            const data = {
                bot_id: id,
                name: 'Начало',
                sort: 0
            }
            fetch(`${process.env.REACT_APP_APIURL}/section`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                mode: 'cors',
                body: JSON.stringify(data)
            }).then((data) => {
                if (data.ok) {
                    return data.json();
                }
            }).then((data) => {
                setSectionList([data]);
                setSelectedSection(data.id)
            });
        }

        const getBot = async (id) => {
            return fetch(`${process.env.REACT_APP_APIURL}/bot/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                mode: 'cors'
            }).then((data) => {
                if (data.ok) {
                    return data.json();
                }
                return new Promise({});
            }).then((data) => {
                setBot(data);
                setSectionList(data.sections.sort((a, b) => +a.sort - b.sort));
                if (data.sections.length === 0) {
                    createBeginSection(data.id);
                }
                if (data.sections.length > 0) {
                    setSelectedSection(data.sections[0].id);
                    data.sections.every((v) => {
                        setMaxSort(Math.max(maxSort, v.sort));
                        return true;
                    });
                    setMessageList(data.sections[0].messageBlocks.sort((a, b) => +a.sort - b.sort));
                }
            });
        }

        if (messageList.length === 0) {
            getBot(params.botId);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token])

    return (
        <>
            <Grid item xs={1}>
                <PageHeader>Редактирование бота {bot.name}</PageHeader>
            </Grid>
            <Grid item xs container spacing={2}>
                <Grid item xs={2}>
                    <BotSectionList selectedSection={selectedSection} setSelectedSection={setSelectedSection} token={token} setMessageList={setMessageList} sectionList={sectionList} setSectionList={setSectionList} bot={bot} maxSort={maxSort} setMaxSort={setMaxSort} />
                </Grid>
                <Grid item xs={8} container alignItems="stretch" justifyContent="center" sx={{
                    height: '80vh'
                }}>
                    <BotMessageList editingMessage={editingMessage} setEditingMessage={setEditingMessage} messageElementRef={messageElementRef} token={token} messageList={messageList} setMessageList={setMessageList} messageListRef={messageListRef} bot={bot} />
                </Grid>
                <Grid item xs={2}>
                    <BotToolList messageList={messageList} selectedSection={selectedSection} token={token} setMessageList={setMessageList} setEditingMessage={setEditingMessage} messageListRef={messageListRef} messageElementRef={messageElementRef} sectionList={sectionList} />
                </Grid>
            </Grid>
        </>
    )
}