import { Grid } from '@mui/material';
import React from 'react';
import './Admin.scss';
import MainMenu from '../MainMenu/MainMenu';
import { Navigate, Outlet } from 'react-router-dom';
import useToken from '../../hooks/useToken';

export default function Admin() {
    const {token}=useToken();

    if(!token){
        return <Navigate to="/" replace />;
    }

    return (
        <Grid container spacing={0}>
            <Grid item xs={2}>
                <MainMenu />
            </Grid>
            <Grid item xs container spacing={1} direction="column" justifyContent="flex-start" alignItems="stretch">
                <Outlet />
            </Grid>
        </Grid>
    )
}