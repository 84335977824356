import { Check, Close, Delete, Edit, EmojiEmotions } from "@mui/icons-material";
import { Fab } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import emojiData from "../../../json/data.json";

export default function BotMessageSimple({ editingMessage, setEditingMessage, messageId, messageElementRef, content, refEmojiList, token, messageList, setMessageList, handleBotMessageRemove, handleShowEmojiList, handleBotMessageCancel }) {

    const [cursorPosition, setCursorPosition] = useState(null);

    const handleBotSimpleMessageSave = (messageId, text = null) => {
        setEditingMessage(0);
        if (!text) {
            text = messageElementRef.current[messageId].innerHTML;
        }
        fetch(`${process.env.REACT_APP_APIURL}/message-block/${messageId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            mode: 'cors',
            body: JSON.stringify({
                content: text
            })
        }).then((data) => {
            if (data.ok) {
                return data.json();
            }
            return null;
        }).then(data => {
            if (data) {
                let ind = 0;
                messageList.every((v, i) => {
                    if (v.id === data.id) {
                        ind = i;
                        return false;
                    }
                    return true;
                });
                setMessageList([...messageList.slice(0, ind), data, ...messageList.slice(ind + 1)]);
                messageElementRef.current[messageId].scrollIntoView({ block: 'center', inline: 'start', behavior: 'auto' });
            }
        });
    }

    const handleBotSimpleMessageEdit = async (messageId) => {
        setEditingMessage(messageId);
        setTimeout(() => {
            messageElementRef.current[messageId].focus();
        }, 500);
    }

    const handleInsertEmoji = (symbol) => {
        refEmojiList.current['emojiList' + messageId].style.display = 'none';
        messageElementRef.current[messageId].focus();
        let sel = window.getSelection();
        sel.removeAllRanges();
        let node = document.createElement('span');
        node.innerHTML = symbol;
        cursorPosition.insertNode(node);
        sel.addRange(cursorPosition);
        sel.collapseToEnd();
    }

    return <Box sx={{
        width: '75%',
        borderRadius: '4px',
        background: '#fff',
        padding: '12px',
        margin: '6px 0',
        position: 'relative',
        '& div:focus-visible': {
            outline: 'none'
        },
        '& .edit-button, & .trash-button': {
            display: 'none'
        },
        '&:hover .edit-button, &:hover .trash-button': {
            display: editingMessage === messageId ? 'none' : 'inline-flex'
        }
    }}>
        <div
            contentEditable={editingMessage === messageId}
            suppressContentEditableWarning={true}
            ref={(element) => messageElementRef.current[messageId] = element}
            dangerouslySetInnerHTML={{ __html: content }}
            onKeyUp={(event) => { setCursorPosition(window.getSelection().getRangeAt(0)); }}
            onClick={(event) => { setCursorPosition(window.getSelection().getRangeAt(0)); }}>
        </div>
        <Box
            sx={{
                display: 'none',
                position: 'absolute',
                width: '50%',
                background: '#fff',
                right: 0,
                zIndex: 500,
                maxHeight: '300px',
                overflowY: 'auto',
                padding: '20px',
                boxSizing: 'border-box',
                flexFlow: 'row wrap',
                justifyContent: 'space-between',
                boxShadow: '0 0 3px #000',
                '& .item-emoji': {
                    cursor: 'pointer',
                    margin: '6px',
                    fontSize: '120%'
                }
            }}
            ref={element => refEmojiList.current['emojiList' + messageId] = element}>
            {emojiData.map((v, i) => <span className="item-emoji" key={'emoji-' + messageId + '-' + i} onClick={(event) => { handleInsertEmoji(event.target.innerHTML) }}>{v.symbol}</span>)}
        </Box>
        <Fab
            size="small"
            color="info"
            className="edit-button"
            onClick={(event) => { handleBotSimpleMessageEdit(messageId) }}
            sx={{
                position: 'absolute',
                right: '50px',
                bottom: '-13px',
                zIndex: 50
            }}><Edit /></Fab>
        <Fab
            size="small"
            color="error"
            className="trash-button"
            onClick={(event) => { handleBotMessageRemove(messageId) }}
            sx={{
                position: 'absolute',
                right: '5px',
                bottom: '-13px',
                zIndex: 50
            }}><Delete /></Fab>
        <Fab
            size="small"
            color="info"
            className="emoji-button"
            onClick={(event) => { handleShowEmojiList() }}
            sx={{
                display: (editingMessage === messageId ? 'inline-flex' : 'none'),
                position: 'absolute',
                right: '95px',
                bottom: '-13px',
                zIndex: 100
            }}><EmojiEmotions /></Fab>
        <Fab
            size="small"
            color="success"
            onClick={(event) => { handleBotSimpleMessageSave(messageId) }}
            sx={{
                display: (editingMessage === messageId ? 'inline-flex' : 'none'),
                position: 'absolute',
                right: '50px',
                bottom: '-13px',
                zIndex: 100
            }}><Check /></Fab>
        <Fab
            size="small"
            color="error"
            onClick={(event) => { handleBotMessageCancel() }}
            sx={{
                display: (editingMessage === messageId ? 'inline-flex' : 'none'),
                position: 'absolute',
                right: '5px',
                bottom: '-13px',
                zIndex: 100
            }}><Close /></Fab>
    </Box>
}